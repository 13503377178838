<template>
  <div class="widget-modules-container">
    <div class="fixed-toolbar-container ">
      <v-toolbar flat dense :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'">
        <v-toolbar-title>{{ $t('widgets.modules.name') }}</v-toolbar-title>
        <v-spacer></v-spacer> 
        <v-text-field v-if="starredModules.length > 1"
          class="mx-4 my-0 py-0" style="width: 30%;"
          v-model="searchQuery"
          append-icon="mdi-magnify"
          :label="$t('widgets.modules.search')"
          single-line
          dense
          clearable
          hide-details
          @click:clear="searchQuery = ''"
        ></v-text-field>
        <v-spacer></v-spacer> 
        <div v-if="starredModules.length > 1">
          <v-btn icon @click="setSortType('A-Z')">
            <v-icon size="20" title="Sort A-Z">mdi-chevron-up</v-icon>
          </v-btn>
          <v-btn icon @click="setSortType('Z-A')">
            <v-icon size="20" title="Sort Z-A">mdi-chevron-down</v-icon>
          </v-btn>
        </div>
      </v-toolbar>
      <v-subheader v-if="filteredModules.length > 0"
      :style="{ 'background-color': $vuetify.theme.dark ? '#1E1E1E !important' : 'white !important' }"
      >{{ $t("widgets.modules.types.starred") }}
        <v-badge class="ml-4 py-1"
          color="#c23b78"
          :content="starredModulesCount"
          overlap
        >
        </v-badge>
      </v-subheader>
    </div>
    <div v-if="filteredModules.length > 0 && !moving">
      <v-card-text>
        <v-list dense subheader> 
          <v-list-item v-for="(starredModule, i) in sortedModules" :key="i" link class="rounded" @click="redirect(starredModule.path)">
            <v-list-item-icon>
              <planete-icon width="24" height="24" :dark="$vuetify.theme.dark">{{ starredModule.icon || "planete-online" }}</planete-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-html="starredModule.name" :title="starredModule.name">
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn icon :color="isStarred(starredModule) ? 'primary' : 'grey'" @click.stop="toggleStar(starredModule)" small>
                <v-icon :title="$t('widgets.modules.remove-bookmarks')">mdi-bookmark</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card-text>
    </div>
    <v-subheader v-else class="subtitle-1">{{ $t('widgets.modules.noModules') }}</v-subheader>
  </div>
</template>
<script>
import Vuex from "vuex";
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";

export default {
  name: "WidgetModules",
  props: {
    moving: Boolean,
  },
  data() {
    return {
      sortType: 'A-Z',
      searchQuery: ''
    };
  },
  computed: {
    ...Vuex.mapState(['widgetBoard', "modules"]),
    starredModules() {
      if (this.modules.length > 0) {
        let starredModules = this.$store.getters.getWidgetDatas("modules").modules.starred;
        return starredModules.map(starred => {
          let moduleFound = this.modules.find(m => m.id == starred.module);
          if (moduleFound) {
            let subModuleFound = moduleFound.subModules.find(sm => sm.id == starred.submodule);
            if (subModuleFound) {
              return { ...starred, name: moduleFound.name + " - " + subModuleFound.name, icon: moduleFound.icon };
            } else return null;
          } else return null;
        }).filter(a => a);
      } else {
        return [];
      }
    },
    filteredModules() {
      return this.starredModules.filter(module =>
        module.name.toLowerCase().includes(this.searchQuery ? this.searchQuery.toLowerCase() : '')
      );
    },
    sortedModules() {
      return this.sortModules([...this.filteredModules]);
    },
    starredModulesCount() {
      return this.filteredModules.length;
    }
  },
  methods: {
    redirect(url) {
      if (url[0] == ".") {
        url = url.substr(1);
      }
      if (url[0] != "/") {
        url = "/" + url;
      }
      window.open(url, "_blank");
    },
    sortModules(modules) {
      switch (this.sortType) {
        case 'A-Z':
          return modules.sort((a, b) => a.name.localeCompare(b.name));
        case 'Z-A':
          return modules.sort((a, b) => b.name.localeCompare(a.name));
      }
    },
    setSortType(type) {
      this.sortType = type;
    },
    async toggleStar(starredModule) {
      let isStarred = this.isStarred(starredModule);
      this.$store.commit("starSubModule", {
        starred: !isStarred,
        moduleId: starredModule.module,
        submoduleId: starredModule.submodule
      });
      let store = this.$_.pick(this.$store.state, ["widgetBoard"]);
      await PlaneteOnlineService.postInterface(store);
    },
    isStarred(starredModule) {
      let widgetModule = this.$store.getters.getWidgetDatas("modules").modules.starred;
      return widgetModule.some(a => a.module == starredModule.module && a.submodule == starredModule.submodule);
    },
  }
}
</script>

<style scoped>
.widget-modules-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fixed-toolbar-container {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
} 
</style>
